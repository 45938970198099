/* eslint-disable jsx-a11y/label-has-associated-control */
import "./contact-form.scss"

import React, { useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"

const ContactForm = () => {
  const [navn, setNavn] = useState(null)
  const [gota, setGota] = useState(null)
  const [postnummar, setPostnummar] = useState(null)
  const [bygd, setBygd] = useState(null)
  const [teldupostur, setTeldupostur] = useState(null)
  const [fartelefon, setFartelefon] = useState(null)
  const [umsokn, setUmsokn] = useState(null)
  const [file, setFile] = useState(null)
  const [legal, setLegal] = useState(false)

  const resetForm = () => {
    setNavn("")
    setGota("")
    setPostnummar("")
    setBygd("")
    setTeldupostur("")
    setFartelefon("")
    setUmsokn("")
    setFile(null)
  }

  const formSubmit = e => {
    e.preventDefault()

    let formData = new FormData()

    formData.set("navn", navn)
    formData.set("gota", gota)
    formData.set("postnummar", postnummar)
    formData.set("bygd", bygd)
    formData.set("teldupostur", teldupostur)
    formData.set("fartelefon", fartelefon)
    formData.set("umsokn", umsokn)
    formData.set("file", file)

    axios({
      method: "post",
      url: "https://wp.mbm.fo/wp-json/contact-form-7/v1/contact-forms/1074/feedback/",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        resetForm()
        console.log("Submit success")
        navigate("/takk-fyri/")
      })
      .catch(error => {
        console.log("Submit error")
        console.log(error)
      })
  }
  return (
    <div className="contact-form">
      <h2 className="contact-form__title">Umsókn til stuðul</h2>
      <form onSubmit={formSubmit} className="contact-form__form">
        <div className="row">
          <div className="col-md-6">
            <div className="contact-form__input-group">
              <input
                type="text"
                name="navn"
                id="navn"
                placeholder="Navn*"
                value={navn}
                onChange={e => setNavn(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="contact-form__input-group">
              <input
                type="text"
                name="gota"
                id="gota"
                placeholder="Gøta*"
                value={gota}
                onChange={e => setGota(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="contact-form__input-group">
              <input
                type="text"
                name="postnummar"
                id="postnummar"
                placeholder="Postnummar"
                value={postnummar}
                onChange={e => setPostnummar(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="contact-form__input-group">
              <input
                type="text"
                name="bygd"
                id="bygd"
                placeholder="Bygd/býur"
                value={bygd}
                onChange={e => setBygd(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="contact-form__input-group">
              <input
                type="email"
                name="teldupostur"
                id="teldupostur"
                placeholder="Teldupostur"
                value={teldupostur}
                onChange={e => setTeldupostur(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="contact-form__input-group">
              <input
                type="text"
                name="fartelefon"
                id="fartelefon"
                placeholder="Fartelefon"
                value={fartelefon}
                onChange={e => setFartelefon(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="contact-form__input-group">
              <textarea
                name="umsokn"
                id="umsokn"
                placeholder="Umsókn"
                value={umsokn}
                onChange={e => setUmsokn(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="contact-form__file-group">
              <input
                type="file"
                name="file"
                id="file"
                onChange={e => setFile(e.target.files[0])}
              />
              <label
                for="file"
                className="button button--upload contact-form__upload-btn"
              >
                Skjøl
              </label>
              {file
                ? file.name
                : "jpg, jpeg, png, gif, pdf, doc, docx, ppt, pptx, odt, avi, ogg, m4a, mov, mp3, mp4, mpg, wav, wmv"}
            </div>
          </div>
          <div className="col-12">
            <label className="checkbox-wrapper" htmlFor="acceptanceForm">
              <input
                type="checkbox"
                name="acceptanceForm"
                id="acceptanceForm"
                onChange={() => setLegal(!legal)}
                checked={legal}
              />
              <span>
                Eg gevi tykkum hervið loyvi til at goyma mínar upplýsingar upp í
                1 ár eftir, at umsóknin er latin.
              </span>
            </label>
          </div>
          <div className="col-12">
            <div className="center-element">
              <button
                type="submit"
                disabled={!legal}
                className="button contact-form__btn"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
